import { FC, RefObject, useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";

import { patchDocument } from "@atlas-ui/services";
import { Document } from "@atlas-ui/types";

import { generateIcsFile } from "@/lib/common/helpers/files-helper";

import dayjs from "dayjs";
import { groupBy, omit } from "lodash";

import { CalendarDay } from "./calendar-day";
import { EventCard, EventCardProps } from "./event-card";

export interface CalendarEvent extends Omit<EventCardProps, "onDownload"> {
  date: string;
}

interface CalendarEventsProps {
  month: string;
  events: CalendarEvent[];
  scrollContainerRef: RefObject<HTMLDivElement>;
}

export const CalendarEvents: FC<CalendarEventsProps> = ({
  month,
  events,
  scrollContainerRef,
}) => {
  const queryClient = useQueryClient();
  const eventsByDay = groupBy(events, (event) =>
    dayjs(event.date).format("DD")
  );

  const { mutate: markDocumentAsDownloaded } = useMutation({
    mutationFn: (documentId: string) => {
      const allQueries = queryClient.getQueriesData(["expiring-documents"]);

      allQueries.forEach(([key, data]) => {
        if (data) {
          queryClient.setQueryData(key, (oldData: Document[] | undefined) => {
            if (!oldData) return oldData!;
            return oldData.map((document) => {
              if (document.id === documentId) {
                return { ...document, isDownloaded: true };
              }
              return document;
            });
          });
        }
      });

      return patchDocument({
        id: documentId,
        isDownloaded: true,
      });
    },
  });

  const scrollToMonth = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      if (!scrollContainerRef.current) return;

      const monthSections =
        scrollContainerRef.current.querySelectorAll("[data-month]");
      const targetMonth = dayjs(month).format("YYYY-MM");

      const targetMonthElement = Array.from(monthSections).find(
        (section) => section.getAttribute("data-month") === targetMonth
      );

      if (!targetMonthElement) return;

      targetMonthElement.scrollIntoView({ behavior: "smooth" });
    },
    [month, scrollContainerRef]
  );

  const downloadEvent = async (event: CalendarEvent) => {
    const dayjsDate = dayjs(event.date);
    const nextDay = dayjsDate.add(1, "day");
    markDocumentAsDownloaded(event.document.id);

    const { url } = await generateIcsFile({
      title: event.title,
      description: event.type,
      start: [dayjsDate.year(), dayjsDate.month(), dayjsDate.date()],
      end: [nextDay.year(), nextDay.month(), nextDay.date()],
      status: "CONFIRMED",
    });
    const link = document.createElement("a");
    link.href = url;
    link.download = `${event.title}.ics`;
    link.click();
  };

  return (
    <div
      data-month={dayjs(month).format("YYYY-MM")}
      data-testid="calendar-events"
      className="flex flex-col pt-2 px-3"
    >
      <button
        onClick={scrollToMonth}
        data-testid="month-scroll-button"
        className="sticky top-0 z-10 bg-background text-base font-semibold text-foreground px-2 h-[32px] leading-[32px] text-left"
      >
        {dayjs(month).format("MMMM YYYY")}
      </button>
      {Object.entries(eventsByDay).map(([day, dayEvents]) => {
        const date = dayjs(dayEvents[0].date);
        return (
          <div
            key={day}
            className="flex p-2"
            data-testid={`day-container-${day}`}
          >
            <CalendarDay day={day} weekday={date.format("ddd")} />
            <div
              className="flex flex-col gap-1 flex-1"
              data-testid={`events-container-${day}`}
            >
              {dayEvents.map((event, index) => (
                <EventCard
                  key={`${day}-${index}`}
                  {...omit(event, ["date"])}
                  onDownload={async () => {
                    await downloadEvent(event);
                  }}
                />
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

import { FC, useCallback, useMemo, useRef, useState } from "react";
import { useMutation } from "react-query";

import {
  Button,
  cn,
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@atlas-ui/components";
import { batchUpdateDocuments } from "@atlas-ui/services";
import { Document } from "@atlas-ui/types";
import { CalendarDaysIcon } from "@heroicons/react/24/solid";

import { useExpiringDocuments } from "@/app/my-documents/hooks/use-expiring-documents";

import dayjs from "dayjs";
import { groupBy } from "lodash";

import { CalendarEvent, CalendarEvents } from "./calendar-events";

const transformToCalendarEvent = (
  document: Document,
  newDocumentIds: string[]
): CalendarEvent => {
  return {
    date: document.expirationDate,
    title: document.name,
    type: document.type,
    status: getStatus(document),
    isDownloaded: document.isDownloaded,
    isNewEvent: newDocumentIds.includes(document.id) || document.isNewEvent,
    document,
  };
};

const getStatus = ({ isAutorenewing, isNotice }: Document): string => {
  if (isAutorenewing) return "Renewing";
  if (isNotice) return "Cancellation Notice Deadline";
  return "Expiring";
};

const groupEventsByMonth = (events: CalendarEvent[]) =>
  groupBy(events, (event) => dayjs(event.date).format("YYYY-MM"));

export const CalendarSheet: FC = () => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [newDocumentIds, setNewDocumentIds] = useState<string[]>([]);

  const { expiringDocuments, invalidateQuery } = useExpiringDocuments({});

  const { mutate: updateDocs } = useMutation({
    mutationKey: ["batchUpdateDocuments"],
    mutationFn: batchUpdateDocuments,
  });

  const handleOpenChange = useCallback(
    (open: boolean) => {
      if (open) {
        const docsToUpdate =
          expiringDocuments?.filter((doc) => doc.isNewEvent) || [];

        if (docsToUpdate.length > 0) {
          const updates = docsToUpdate.map((doc) => ({
            id: doc.id,
            isNewEvent: false,
          }));

          setNewDocumentIds(docsToUpdate.map((doc) => doc.id));

          updateDocs(updates as { id: string } & Partial<Document>[], {
            onSuccess: () => {
              invalidateQuery();
            },
          });
        }
      } else {
        setNewDocumentIds([]);
      }
    },
    [expiringDocuments, invalidateQuery, updateDocs]
  );

  const eventsByMonth = useMemo(() => {
    return groupEventsByMonth(
      expiringDocuments?.map((document) =>
        transformToCalendarEvent(document, newDocumentIds)
      ) ?? []
    );
  }, [expiringDocuments, newDocumentIds]);

  const hasNewEvents =
    expiringDocuments?.some((event) => event.isNewEvent) ?? false;

  return (
    <Sheet onOpenChange={handleOpenChange}>
      <SheetTrigger asChild>
        <Button
          data-testid="calendar-trigger-button"
          variant="icon"
          className={cn(
            "rounded-full border-none active:bg-transparent relative",
            hasNewEvents && "bg-muted"
          )}
        >
          <CalendarDaysIcon className="w-6 h-6 text-[#FF3172]" />
          {hasNewEvents && (
            <div
              data-testid="calendar-notification-badge"
              className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full w-4 h-4 flex items-center justify-center"
            />
          )}
        </Button>
      </SheetTrigger>
      <SheetContent
        data-testid="calendar-sheet-content"
        className="p-0 flex flex-col gap-0 max-h-screen"
        hideOverlay
      >
        <SheetHeader
          data-testid="calendar-sheet-header"
          className="sticky top-0 bg-background flex flex-row px-4 py-0 gap-2 min-h-[56px] items-center border-b border-b-border"
        >
          <CalendarDaysIcon className="w-[18px] h-[18px] text-[#FF3172]" />
          <SheetTitle
            data-testid="calendar-sheet-title"
            className="flex items-center !mt-0 text-base font-semibold"
          >
            Upcoming Events
          </SheetTitle>
        </SheetHeader>
        <div
          data-testid="calendar-events-container"
          ref={scrollContainerRef}
          className="overflow-y-auto relative"
        >
          {Object.entries(eventsByMonth).map(([month, events]) => (
            <CalendarEvents
              key={month}
              month={`${month}-01`}
              events={events}
              scrollContainerRef={scrollContainerRef}
            />
          ))}
        </div>
      </SheetContent>
    </Sheet>
  );
};

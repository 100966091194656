import { FC } from "react";

interface CalendarDayProps {
  day: string;
  weekday: string;
}

export const CalendarDay: FC<CalendarDayProps> = ({ day, weekday }) => (
  <div
    data-testid="calendar-day-container"
    className="flex flex-col pr-2 pt-2 border-r border-r-border text-center w-[30px]"
  >
    <span
      data-testid="calendar-day-number"
      className="text-[#FF3172] text-base font-bold"
    >
      {day}
    </span>
    <span
      data-testid="calendar-day-weekday"
      className="text-muted-foreground text-[10px]"
    >
      {weekday}
    </span>
  </div>
);

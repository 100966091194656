"use client";

import {
  FC,
  HTMLAttributes,
  KeyboardEventHandler,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";

import { Button } from "@atlas-ui/components";
import { UserProfile, useUser } from "@auth0/nextjs-auth0/client";

import { InviteDialog } from "@/components/common/dialogs/invite-dialog";
import { OrganizationMembersSection } from "@/components/layout/organization-members-section";
import { UserDropdownMenu } from "@/components/layout/user-dropdown-menu";
import { useOrganizationMembers } from "@/lib/common/hooks/use-organization-members";
import { cn } from "@/lib/utils";

import mixpanel from "mixpanel-browser";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";

import BrandLogo from "../../assets/brand.svg";
import { CalendarSheet } from "./calendar/calendar-sheet";

interface LayoutHeaderProps extends HTMLAttributes<HTMLDivElement> {
  user?: UserProfile;
}

const ROUTES_WITHOUT_HEADER = ["/auth", "/onboarding"];

interface HeaderLink {
  text: string;
  href: string;
}

const getLinksByPath = (pathname: string | null): HeaderLink[] => [
  {
    text: "My Documents",
    href: "/my-documents",
  },
  {
    text: "Compare",
    href: "/compare",
  },
  {
    text: "Insights",
    href: "/insights",
  },
];

export const LayoutHeader: FC<LayoutHeaderProps> = ({
  className,
  user: userAsProp,
  ...props
}) => {
  const { user: contextUser, isLoading } = useUser();
  const user = userAsProp ?? contextUser;
  const [hidden, setHidden] = useState<boolean>(true);
  const [links, setLinks] = useState<HeaderLink[]>([]);
  const [isInviting, setIsInviting] = useState<boolean>(false);
  const pathname = usePathname();

  const orgMembersProps = useMemo(
    () => ({
      enabled: !!userAsProp || !!contextUser,
    }),
    [userAsProp, contextUser]
  );

  const {
    onMemberRemoved,
    reload: reloadMembers,
    loadedMembers,
    isOwner,
  } = useOrganizationMembers(orgMembersProps);

  const onInvitationIntent = () => {
    mixpanel.track("action-modal-contributors");
    setIsInviting(true);
  };

  const handleEnterKeyPress: KeyboardEventHandler<HTMLButtonElement> = (
    event
  ) => {
    if (event.key === "Enter") {
      onInvitationIntent();
    }
  };

  useLayoutEffect(() => {
    setHidden(
      ROUTES_WITHOUT_HEADER.some((route) =>
        window.location.pathname.startsWith(route)
      )
    );

    setLinks(getLinksByPath(pathname));
  }, [pathname]);

  if (hidden) {
    return null;
  }

  return (
    <header
      className={cn(
        "flex bg-white pt-6 pb-4 px-12 border-b border-b-muted",
        "items-center gap-12 text-white",
        className
      )}
      id="header"
      {...props}
    >
      <Link href="/">
        <Image
          src={BrandLogo}
          alt="DocSnap Logo"
          width={144}
          height={40}
          priority
        />
      </Link>

      <div className="flex-1 flex">
        <div className={cn("hidden", "lg:flex-1 lg:flex lg:gap-6")}>
          {user &&
            links?.map((link, index) => (
              <Button
                key={link.href ?? index}
                variant="ghost"
                className="rounded-none hover:bg-transparent"
                asChild
              >
                <Link
                  href={link.href}
                  className={cn(
                    "h-[40px] px-4 py-2 border-b-transparent",
                    window.document.location.pathname === link.href
                      ? "text-foreground cursor-default"
                      : "text-muted-foreground hover:text-primary"
                  )}
                >
                  {link.text}
                </Link>
              </Button>
            ))}
        </div>
      </div>
      <div className="flex items-center gap-4">
        {!user && (
          <Button variant="ghost" asChild>
            <Link
              href="/api/auth/login"
              className="text-sm font-medium leading-6"
            >
              Sign in
            </Link>
          </Button>
        )}
        {user && (
          <>
            <InviteDialog
              members={loadedMembers}
              isOpen={isInviting}
              isOwner={isOwner}
              onMemberRemoved={onMemberRemoved}
              onMemberInvited={reloadMembers}
              onClose={() => setIsInviting(false)}
            />
            <button
              className="flex items-center gap-4"
              onClick={onInvitationIntent}
              onKeyDown={handleEnterKeyPress}
            >
              <OrganizationMembersSection members={loadedMembers} />

              <div className="flex items-center gap-4">
                <Button
                  asChild
                  variant="secondary"
                  data-testid="invite-contributors"
                >
                  <span>Invite Contributors</span>
                </Button>
              </div>
            </button>
          </>
        )}
        {user && <CalendarSheet />}
        {!isLoading && user && <UserDropdownMenu user={user} />}
      </div>
    </header>
  );
};

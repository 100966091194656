import { FC } from "react";

import { Button, cn } from "@atlas-ui/components";
import { Document } from "@atlas-ui/types";

import { Download } from "lucide-react";
import Link from "next/link";

export interface EventCardProps {
  title: string;
  type: string;
  status: string;
  isDownloaded: boolean;
  isNewEvent: boolean;
  onDownload: () => void;
  document: Document;
}

export const EventCard: FC<EventCardProps> = ({
  title,
  type,
  status,
  isDownloaded,
  onDownload,
  isNewEvent,
  document,
}) => {
  return (
    <div data-testid="event-card" className="p-2 w-full">
      <div
        data-testid="event-card-content"
        className="flex flex-col p-2 bg-primary-foreground rounded-[8px] gap-[4px] w-full group relative"
      >
        {isNewEvent && (
          <div
            data-testid="event-card-notification-badge"
            className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full w-4 h-4 flex items-center justify-center"
          />
        )}
        <Link
          href={`/documents/${document.id}`}
          data-testid="event-card-title"
          className="text-sm font-medium text-foreground hover:underline hover:text-primary"
          target="_blank"
        >
          {title}
        </Link>
        <span
          data-testid="event-card-type"
          className="text-xs text-muted-foreground"
        >
          {type}
        </span>
        <div
          data-testid="event-card-footer"
          className="flex items-center justify-between"
        >
          <span
            data-testid="event-card-status"
            className="text-primary text-xs"
          >
            {status}
          </span>
          <Button
            data-testid="download-event-button"
            variant="ghost"
            size="xs"
            onClick={onDownload}
            className={cn(
              "opacity-0 group-hover:opacity-100 transition-opacity duration-300"
            )}
          >
            <Download
              className={cn(
                "w-4 h-4 text-primary",
                isDownloaded && "text-muted-foreground"
              )}
            />
          </Button>
        </div>
      </div>
    </div>
  );
};

import { useQuery, useQueryClient } from "react-query";

import { getExpiringDocuments } from "@atlas-ui/services";
import { Document } from "@atlas-ui/types";

export interface UseExpiringDocumentsProps {
  documentTypeId?: string | null;
  tags?: string[];
  parties?: string[];
  search?: string;
  transform?: (data: Document[]) => unknown;
}

type ExpiringDocumentsQueryKey = [
  "expiring-documents",
  string,
  string,
  string,
  string
];

export const useExpiringDocuments = <T = Document[]>({
  documentTypeId,
  tags,
  parties,
  search,
  transform,
}: UseExpiringDocumentsProps): {
  expiringDocuments: T;
  isLoadingExpiringDocuments: boolean;
  invalidateQuery: () => void;
} => {
  const queryClient = useQueryClient();
  const queryKey: ExpiringDocumentsQueryKey = [
    "expiring-documents",
    documentTypeId ?? "all",
    tags?.join(",") ?? "all",
    parties?.join(",") ?? "all",
    search ?? "all",
  ];

  const { data, isLoading } = useQuery<Document[]>({
    queryKey: queryKey,
    queryFn: () =>
      getExpiringDocuments({ documentTypeId, tags, parties, search }),
  });

  const prepareData = (data: Document[]) => {
    if (transform) {
      return transform(data);
    }

    return data;
  };

  return {
    expiringDocuments: (data ? prepareData(data) : []) as T,
    isLoadingExpiringDocuments: isLoading,
    invalidateQuery: () => queryClient.invalidateQueries(queryKey),
  };
};
